import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "secondconsultant";
  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // this.deepLink()
  }

  deepLink() {
    let navigate = navigator.userAgent.toLowerCase();
    let isAndroid = navigate.indexOf('android') > -1;
    let isIphone = navigate.indexOf("iphone") > -1;
    if (isIphone == true) {
      let app = {
        launchApp() {
          window.location.replace('secondconsult://');
          // setTimeout(() => {
          //   this.openAppStore();
          // }, 500);
        },
        // openAppStore() {
        //   window.location.href = 'https://itunes.apple.com/us/app/secondconsult/1608310955';
        // }
      }
      app.launchApp();
    } else if (isAndroid == true) {
      let app = {
        launchApp() {
          window.location.replace('secondconsult://');
          // setTimeout(() => {
          //   this.openPlayStore();
          // }, 500);
        },
        // openPlayStore() {
        //   window.location.href = 'https://play.google.com/store/apps/details?id=com.secondconsult';
        // }
      }
      app.launchApp();
    }
  }
}
