import { APP_CONSTANTS } from "./../../config.component";
import { PaymentfailureService } from "./../../payment/paymentfailure/paymentfailure.service";
import { DatePipe } from "@angular/common";
import { DoctorListingComponent } from "./../../doctor-listing/doctor-listing.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Route } from "@angular/compiler/src/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { FooterService } from "./footer.service";
import { stringify } from "querystring";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  providers: [DatePipe],
})
export class FooterComponent implements OnInit {
  mainUrl: any;
  baseUrl: any;
  specialtyList: any;
  currentYear: any;
  doctorSignupUrl = environment.doctorUrl + "/#/registration/signup";
  copyright_information: any;
  constructor(
    private api: FooterService,
    private router: Router,
    private _route: ActivatedRoute,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.mainUrl = environment.mainUrl;
    this.baseUrl = environment.baseUrl;
    this.api.getSpecialties().subscribe((jsonData) => {
      this.specialtyList = jsonData.data;
    });
    let currentYear = new Date().getFullYear();
    let site_name = APP_CONSTANTS.site_name;

    this.copyright_information = currentYear + " " + site_name;
  }
  signIn() {
    window.location.href = this.mainUrl + "/#login";
  }
  signUp() {
    window.location.href = this.mainUrl + "/#/sign-up";
  }
  @ViewChild(DoctorListingComponent, { static: true })
  footerComponent: DoctorListingComponent;
  sid: any = [];

  selectSepciality(spec_id) {
    this.sid.push(spec_id);
    let date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let speciality_id = []; //= this.sid.join();
    if (this.sid.length == 1) {
      speciality_id = this.sid;
    } else {
      speciality_id = this.sid.slice(this.sid.length - 1);
    }
    let spcl_id = speciality_id.join();

    this.router.navigate(["/"], {
      relativeTo: this._route,
      queryParams: {
        date: date,
        start: 0,
        limit: 0,
        maxlen: 5,
        consult: "video",
        startdate: date,
        specialist: spcl_id, //"speciality"
        language: "",
        symptoms: "",
        maindropdown: undefined,
        availability: undefined,
        mainproce: "",
        procedure: "",
        condition: "",
      },
    });
    window.scrollTo(0, 0);
  }
}
