export const environment = {
  production: false,

  noPopList: 10,

  apiUrl: "https://api-prod.secondconsult.com/api/",
  rladminUrl: "https://rladmin.secondconsult.com",
  adminUrl: "https://admin.secondconsult.com",
  rlUrl: "https://rl.secondconsult.com",
  doctorUrl: "https://doctor.secondconsult.com",
  baseUrl: "https://secondconsult.com",
  paymentUrl: "https://api-prod.secondconsult.com/api/",
  mainUrl: "https://app.secondconsult.com",
  patientUrl: "https://patient.secondconsult.com/",
  assetUrl: "https://api-prod.secondconsult.com",
  razorpay_key_id: "rzp_live_92tV9GCKAPeurF",
  razorpay_key_secret: "I8nU7VTVFwmAIjS7Vo2Fllbc"
};