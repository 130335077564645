import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  timeOut = "";
  constructor(private cookieService: CookieService, private router: Router) {}

  ngOnInit() {
    const url = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("access_token", "/", "." + url);
    this.timeOut = "cleared";
    setTimeout(() => {
      this.router.navigate(["/login"]);
    }, 100);
  }
}
