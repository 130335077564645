import { time_availability } from "./doctor-listing/doctor-listing.config";
import { Pipe, PipeTransform } from "@angular/core";
import { Time } from "@angular/common";
@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.city_name.toLowerCase().includes(searchText);
    });
  }
}
@Pipe({ name: "hour" })
export class TimeFormat implements PipeTransform {
  transform(time: any): any {
    if (time == "-") return time;
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    let part = hour >= 12 ? "PM" : "AM";
    min = (min + "").length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + "").length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
  }
}

@Pipe({ name: "truncate" })
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    limit = 170,
    completeWords = false,
    ellipsis = "..."
  ) {
    let returnVal;
    if (completeWords) {
      returnVal = value.replace(/(([^\s]+\s\s*){30})(.*)/, "$1" + ellipsis);
    } else {
      returnVal =
        value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
    return returnVal;
  }
}
