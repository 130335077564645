import { HttpErrorResponse, HttpInterceptor } from "@angular/common/http";
import { LoginService } from "../login/login.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SCInterceptor implements HttpInterceptor {
  header: any;
  constructor(private loginservice: LoginService, public router: Router) {}
  canActivate() {
    {
      if (this.loginservice.isLoggedIn()) {
        return true;
      } else {
        return false;
      }
    }
  }
  intercept(
    req: import("@angular/common/http").HttpRequest<any>,
    next: import("@angular/common/http").HttpHandler
  ): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    const token = this.loginservice.getToken();

    if (this.canActivate()) {
      this.header = req.headers.set("Authorization", "Bearer " + token);
    }

    const userdata = this.loginservice.isLoggedIn();

    let headers = this.header;
    if (userdata != null) {
      // return next.handle(req.clone({ headers }));
      return next.handle(req.clone({ headers })).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
           if(error.error.reason == "otp_not_verified"){
              this.router.navigate(['registration/otp-verification']);
           }else{
              this.loginservice.logout();
           }           
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(req.clone({ headers }));
    }
  }
}
