import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class DoctorListingService {
  constructor(
    private http: HttpClient,
    private _route: ActivatedRoute,
    private router: Router
  ) {}

  doctorDetails(
    doctor_id: any,
    selectedDate: any,
    consult_type: any,
    filteropt: any
  ): Observable<any> {
    let param = new HttpParams();
    let apiURL =
      API_URL +
      "get-doctor-details/" +
      doctor_id +
      "/" +
      selectedDate +
      "/" +
      consult_type;
      param = param.append("timezone", filteropt.timezone);
      this.router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        timezone: filteropt.timezone,
      },
    });
    return this.http.get(apiURL, {params: param});
  }
  /* get all doctor category name*/
  getDoctorCategory(): Observable<any> {
    // let apiURL = API_URL + "get-doctor-category";
    let apiURL = API_URL + "get-all-specialist-listing";
    return this.http.get(apiURL);
  }
  // getAllsymptom(): Observable<any> {
  //   let apiURL = API_URL + "get-allsymptom";
  //   // let apiURL = API_URL + 'get-all-medicalcondition/';
  //   return this.http.get(apiURL);
  // }
  getLanguage(): Observable<any> {
    // let apiURL = API_URL + "get-all-language";get-alllang-listing
    let apiURL = API_URL + "get-alllang-listing";

    return this.http.get(apiURL);
  }
  // getOrganization(): Observable<any> {
  //   let apiURL = API_URL + "get-all-organization";
  //   return this.http.get(apiURL);
  // }
  // getProcedure(): Observable<any> {
  //   let apiURL = API_URL + "get-allprocedure";
  //   return this.http.get(apiURL);
  // }
  getAllCity(): Observable<any> {
    let apiURL = API_URL + "get-allcity-bycountry";
    return this.http.get(apiURL);
  }
  // getMedicalCondition(): Observable<any> {
  //   let apiURL = API_URL + "get-past-midical-history";
  //   return this.http.get(apiURL);
  // }
  getCompleteList(): Observable<any> {
    let apiURL = API_URL + "get-doctor-category-flag";
    return this.http.get(apiURL);
  }

  /* filter api end*/

  listFilterNew(filteropt): Observable<any> {
    let param = new HttpParams();

    // Begin assigning parameters
    param = param.append("page", filteropt.page);
    param = param.append(
      "city",
      filteropt.city == undefined ? "" : filteropt.city
    );
    param = param.append(
      "date",
      filteropt.date == undefined ? "" : filteropt.date
    );
    param = param.append(
      "gender",
      filteropt.gender == undefined ? "" : filteropt.gender
    );
    param = param.append(
      "availability",
      filteropt.availability == undefined ? "" : filteropt.availability
    );
    param = param.append(
      "sort",
      filteropt.sort == undefined ? "" : filteropt.sort
    );
    param = param.append(
      "organization",
      filteropt.organization == undefined ? "" : filteropt.organization
    );
    // param = param.append(
    //   "mainproce",
    //   filteropt.mainproce == undefined || filteropt.mainproce == ""
    //     ? ""
    //     : filteropt.mainproce.type + "_" + filteropt.mainproce.id
    // );
    param = param.append("mainproce", filteropt.mainproce);
    param = param.append("consult", filteropt.consult);
    param = param.append("startdate", filteropt.startdate);
    param = param.append("specialist", filteropt.specialist);
    param = param.append("symptoms", filteropt.symptoms);
    // param = param.append("start", filteropt.start);
    param = param.append("language", filteropt.language);
    // param = param.append("limit", filteropt.limit);
    param = param.append("procedure", filteropt.procedure);
    param = param.append("condition", filteropt.condition);
    param = param.append("maxlen", filteropt.maxlen);
    param = param.append("is_free", filteropt.is_free);
    param = param.append("timezone", filteropt.timezone);
    // let data = {
    //   language: JSON.stringify(filteropt.language),
    //   symptoms: JSON.stringify(filteropt.symptoms),
    //   procedure: JSON.stringify(filteropt.procedure),
    //   condition: JSON.stringify(filteropt.condition),
    //   specialist: JSON.stringify(filteropt.specialist),
    //   maindropdown: JSON.stringify(filteropt.mainproce),
    // };
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        page: filteropt.page,
        per_page: filteropt.limit,
        date: filteropt.date,
        start: filteropt.start,
        limit: filteropt.limit,
        maxlen: filteropt.maxlen,
        consult: filteropt.consult,
        startdate: filteropt.startdate,
        gender: filteropt.gender,
        language: filteropt.language,
        symptoms: filteropt.symptoms,
        // maindropdown: filteropt.mainproce,
        availability: filteropt.availability,
        sort: filteropt.sort,
        organization: filteropt.organization,
        city: filteropt.city,
        mainproce: filteropt.mainproce,
        // mainproce: filteropt.mainproce == undefined || filteropt.mainproce == ""
        // ? ""
        // : filteropt.mainproce.type + "_" + filteropt.mainproce.id,
        procedure: filteropt.procedure,
        // specialist: data.specialist,
        specialist: filteropt.specialist,
        condition: filteropt.condition,
        is_free: filteropt.is_free,
        timezone: filteropt.timezone,
      },
    });

    // let apiURL = API_URL + "get-doctorby-search/" + offset;
    let apiURL = API_URL + "home-page-data";
    return this.http.get(apiURL, {
      params: param,
    });
  }

  getSpecialistbySearch(term: any): Observable<any> {
    let apiURL = API_URL + "get-doctor-category-byinput/" + term;
    return this.http.get(apiURL);
  }
  getPatientData() {
    let apiURL = API_URL + "get-user-details";
    return this.http.get(apiURL);
  }
  addToAppointmentCart(data) {
    let apiURL = API_URL + "appointment-carts";
    return this.http.post(apiURL, data);
  }

  getIPAddressApi()
  {
     return this.http.get("https://api.ipify.org/?format=json");
  }

  getBrowserTimezoneApi(ip_address)
  {
    let apiURL = API_URL + "user/" + ip_address + "/timezone";
    return this.http.get(apiURL);
  }

  updateUserTimezoneApi(user_id, timezone)
  {
    let apiURL = API_URL + "update/user/" + user_id + "/timezone";
    return this.http.put(apiURL, { timezone: timezone });
  }
}
