import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../api.service";

// user.ts
export interface LoginResponse {
  access_token: string;
  data: any;
  name: string;
  status: string;
  message: string;
}

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private router: Router,
    private api: ApiService
  ) {}

  getToken() {
    return this.cookieService.get("access_token");
  }

  getUserData() {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }
  }
  isExpired() {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData.role;
  }

  login(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("email", user.email);
    param = param.append("password", user.pass);
    param = param.append("remember", user.remember);
    return this.http.post(API_URL + "login", param);
  }
  
  loginWithOtp(mobile_no, otp): Observable<any> {
    let data = {
      mobile_no: mobile_no,
      otp: otp
    };

    return this.http.post(API_URL + "login-with-otp", data);
  }

  sendLoginOtp(mobileNo: string): Observable<any> {
    let data = {
      mobile_no: mobileNo
    };

    return this.http.post(API_URL + "send-login-otp", data);
  }

  // After login save token and other values(if any) in localStorage
  setUser(response: LoginResponse, registraion = false) {
    var myDate = new Date();
    const url = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    myDate.setMonth(myDate.getMonth() + 12);
    localStorage.setItem("name", response.name || "");
    localStorage.setItem("access_token", response.access_token);
    document.cookie =
      "access_token=" +
      response.access_token +
      ";expires=" +
      myDate +
      `;domain=.${url};path=/`;
    if (!registraion) this.redirectUser();
  }

  redirectUser() {
    const role = this.getUserRole();

    switch (role) {
      case "patient":
        this.navigateSelectedUser();
        break;

      case "sub_admin":
        window.location.href = environment.adminUrl;
        break;

      case "R":
        window.location.href = environment.rlUrl;
        break;

      case "RA":
        window.location.href = environment.rlUrl;
        break;

      case "RS":
        window.location.href = environment.rlUrl;
        break;

      case "doctor":
        window.location.href = environment.doctorUrl;
    }
  }

  // Checking if token is set
  isLoggedIn() {
    if (this.isExpired() || this.getUserRole() != "patient") {
      return false;
    }
    return true;
  }

  // After clearing localStorage redirect to login screen
  logout() {
    const url = environment.baseUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("access_token", "/", "." + url);
    // window.location.href = "/#/login";
    // this.router.navigateByUrl("/login");
    // let patientUrl = environment.patientUrl + "/#/logout";
    // let result = this.http.get(patientUrl);
    // result.subscribe((res) => {
    //   console.log(res, "inside");
    // });
    setTimeout(() => {
      this.router.navigateByUrl("/login");
    }, 100);
  }
  getPatientData() {
    this.api.getPatientData().subscribe(
      (response: any) => {
        let patientInfo = response.data;
        return patientInfo;
      },
      (err) => {}
    );
  }
  navigatePatient() {
    this.api.getPatientData().subscribe(
      (response: any) => {
        let patientInfo = response.data;
        if (
          patientInfo.otp_verified == 1 &&
          patientInfo.is_verify == 1 &&
          patientInfo.is_profile_completed == 1
        ) {
          this.router.navigateByUrl("/"); //goto homepage
        } else {
          patientInfo.otp_verified == 0
            ? this.router.navigateByUrl("/registration/otp-verification")
            : patientInfo.is_verify == 0
            ? this.router.navigateByUrl("/registration/id-verification")
            : this.router.navigateByUrl("/registration/patient-profile");
        }
      },
      (err) => {}
    );
  }
  navigateSelectedUser() {
    this.api.getPatientData().subscribe(
      (response: any) => {
        let patientInfo = response.data;
        if (
          patientInfo.otp_verified == 1 &&
          // patientInfo.is_verify == 1 &&
          patientInfo.is_profile_completed == 1
        ) {
          // let docId;
          // docId = JSON.parse(localStorage.getItem("doctor_detils"));
          // if (docId) {
          let product_visit = localStorage.getItem("product_visit");
          if (product_visit == "true") {
            localStorage.setItem("product_visit", "");
            setTimeout(() => {
              this.router.navigateByUrl("/product");
            }, 100);
          }
          let appointment_data = JSON.parse(localStorage.getItem("appointment_data"));
          let membership_data = JSON.parse(localStorage.getItem("membership_data"));
          if (appointment_data) {
            appointment_data.consultation_mode == "second_opinion"
              ? this.router.navigate(["confirm-pay"])
              : this.router.navigate(["review-book"]);
          } else {
            this.router.navigateByUrl("/"); //goto homepage
          }
          if(membership_data && patientInfo.membership_status == false){
            this.router.navigate(['membership-confirmation']);
          }else{
            if(patientInfo.membership_status == true){
              localStorage.removeItem(membership_data);
              this.router.navigateByUrl("/");
            }
          }
        } else {
          // patientInfo.otp_verified == 0
          //   ? this.router.navigateByUrl("/registration/otp-verification")
          //   : patientInfo.is_verify == 0
          //   ? this.router.navigateByUrl("/registration/id-verification")
          //   : this.router.navigateByUrl("/registration/patient-profile");
          this.router.navigateByUrl("/registration/otp-verification")
        }
      },
      (err) => {}
    );
  }
}
