import { FooterComponent } from "src/app/layout/footer/footer.component";
import { HeaderComponent } from "src/app/layout/header/header.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HZDatePickerService } from "ng2-hz-datepicker";
// Routing Module
import { AppRoutes } from "./app.routing";
import { ApplayoutComponent } from "./layout/applayout/applayout.component";
// Directives
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ApiService } from "./api.service";
import { ReactiveFormsModule } from "@angular/forms";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
// for search section
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { JwtModule } from "@auth0/angular-jwt";
import { SCInterceptor } from "./_interceptor/sc.interceptor";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MatSnackBarModule } from "@angular/material";
import { LogoutComponent } from "./logout/logout.component";
import { WindowRefService } from "./window-ref.service";

export class HZDatePickerModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: HZDatePickerModule,
      providers: [HZDatePickerService],
    };
  }
}
export function jwtTokenGetter() {
  return localStorage.getItem("access_token");
}
@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      initialNavigation: "enabled",
    }),
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: () => {
    //       return localStorage.getItem("access_token");
    //     },
    //     whitelistedDomains: [],
    //     blacklistedRoutes: [],
    //   },
    // }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
      },
    }),
    NgbModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
    LogoutComponent,
    ApplayoutComponent,
    FooterComponent,
    HeaderComponent,
  ],
  providers: [
    // DatePipe,
    ApiService,
    WindowRefService,
    HZDatePickerService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SCInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
