import { ApiService } from "../../api.service";
import { Router } from "@angular/router";
import { LoginService } from "src/app/login/login.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  show: boolean = false;
  userData = [];
  is_next_apt;
  aptUrl;

  constructor(
    public loginService: LoginService,
    private router: Router,
    public snackBar: MatSnackBar,
    private api: ApiService
  ) {
    this.userData = this.loginService.getUserData();
  }

  async ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      await this.api.getPatientData().subscribe((response: any) => {
        let patientInfo = response.data;
        this.is_next_apt = patientInfo.next_appointment;
        if(patientInfo.timezone === 'Asia/Kolkata'){
          this.is_next_apt.timezone = 'IST'
        }
        this.aptUrl =
          environment.patientUrl +
          "#/appointment-details;id=" +
          this.is_next_apt.bookingunique_id;
      });
    }
  }

  toggleCollapse() {
    this.show = !this.show;
  }
  logout() {
    this.loginService.logout();
  }

  // goto patient dashboard
  gotoDashboard() {
    this.api.getPatientData().subscribe(
      (response: any) => {
        let patientInfo = response.data;
        if (patientInfo.is_profile_completed == 1) {
          window.location.href = environment.patientUrl;
        } else {
          if (patientInfo.otp_verified == 0) {
            this.router.navigateByUrl("/registration/otp-verification");
            this.openSnackBar(
              "Before going to profile complete OTP-verification",
              ""
            );
          } else if (patientInfo.is_verify == 0) {
            this.router.navigateByUrl("/registration/id-verification");
            this.openSnackBar(
              "Before going to profile complete ID-verification details",
              ""
            );
          } else {
            this.router.navigateByUrl("/registration/patient-profile");
            this.openSnackBar(
              "Before going to profile complete your profile",
              ""
            );
          }
        }
      },
      (err) => {}
    );
  }
  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = "center";
    config.verticalPosition = "top";
    config.panelClass = ["green-snackbar"];
    this.snackBar.open(message, action, config);
  }
}
