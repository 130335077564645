export const consultationFilter = [
  { id: "video", name: "Video Consultation" },
  { id: "audio", name: "Audio Consultation" },
  // { id: "chat", name: "Chat Consultation" },
  { id: "second_opinion", name: "Second Opinion" },
];
export const genderFilter = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "", name: "Any" },
];
export const availabilityFilter = [
  // { id: "1", name: "Next Hour" },
  { id: "2", name: "Today" },
  { id: "3", name: "tomorrow" },
];
export const sortbyFilter = [
  { id: "default", name: "Default Order" },
  // { id: "review", name: "Reviews" },
  { id: "fee-asc", name: "Fees" },
  // { id: "fee-desc", name: "Fees (Max to Min)" },
  { id: "a-z", name: "Doctors (A to Z)" },
  // { id: "z-a", name: "Doctors (Z to A)" },
  { id: "exp-desc", name: "Experience" },
];
export const consultationOffer = [
  { id: "", name: "All Consultations" },
  { id: "1", name: "Free Covid19 Consultations" },
  // { id: "0", name: "Paid Consultations" },
];
// export const time_availability = [
//   [
//     "9:00AM",
//     "",
//     "10.00PM",
//     "10:30PM",
//     "11:00AM",
//     "11:30AM",
//     "3:00PM",
//     "3:30PM",
//     "4:00PM",
//     "4:30PM",
//     "5:00PM",
//     "",
//     "",
//     "6:30PM",
//     "",
//     "",
//     "8:00PM",
//     "8.30PM",
//   ],
//   [
//     "",
//     "9:30AM",
//     "10.00PM",
//     "10.30PM",
//     "11:00AM",
//     "11:30AM",
//     "",
//     "3:30PM",
//     "4:00PM",
//     "4:30PM",
//     "",
//     "",
//     "6:00AM",
//     "6:30PM",
//     "",
//     "7:30PM",
//     "",
//     "8.30PM",
//   ],
//   [
//     "9:AM",
//     "",
//     "10:00PM",
//     "10.30PM",
//     "11:00AM",
//     "11:30AM",
//     "3:00PM",
//     "3:30PM",
//     "4:00PM",
//     "4:30PM",
//     "5:00PM",
//     "",
//     "6:00AM",
//     "6:30PM",
//     "",
//     "7:30PM",
//     "8:00PM",
//     "8.30PM",
//   ],
//   [
//     "",
//     "",
//     "10:00PM",
//     "10.30PM",
//     "11:00AM",
//     "11:30AM",
//     "3:00PM",
//     "3:30PM",
//     "4:00PM",
//     "4:30PM",
//     "5:00PM",
//     "",
//     "6:00AM",
//     "6:30PM",
//     "",
//     "7:30PM",
//     "8:00PM",
//     "",
//   ],
//   [
//     "",
//     "",
//     "10.00PM",
//     "10.30PM",
//     "11:00AM",
//     "11:30AM",
//     "3:00PM",
//     "3:30PM",
//     "4:00PM",
//     "4:30PM",
//     "5:00PM",
//     "",
//     "6:00AM",
//     "6:30PM",
//     "",
//     "7:30PM",
//     "8:00PM",
//     "8.30PM",
//   ],
// ];

export const time_availability = [
  {
    "9:00": "-",
    "9:30": "9:30",
    "10:00": "-",
    "10:30": "10:30",
    "11:00": "-",
    "11:30": "11:30",
    "15:00": "-",
    "15:30": "15:30",
    "16:00": "-",
    "16:30": "16:30",
    "17:00": "-",
    "17:30": "17:30",
  },
  {
    "9:00": "-",
    "9:30": "9:30",
    "10:00": "-",
    "10:30": "10:30",
    "11:00": "-",
    "11:30": "11:30",
    "15:00": "-",
    "15:30": "15:30",
    "16:00": "-",
    "16:30": "16:30",
    "17:00": "-",
    "17:30": "17:30",
  },
  {
    "9:00": "-",
    "9:30": "9:30",
    "10:00": "-",
    "10:30": "10:30",
    "11:00": "-",
    "11:30": "11:30",
    "15:00": "-",
    "15:30": "15:30",
    "16:00": "-",
    "16:30": "16:30",
    "17:00": "-",
    "17:30": "17:30",
  },
  {
    "9:00": "-",
    "9:30": "9:30",
    "10:00": "-",
    "10:30": "10:30",
    "11:00": "-",
    "11:30": "11:30",
    "15:00": "-",
    "15:30": "15:30",
    "16:00": "-",
    "16:30": "16:30",
    "17:00": "-",
    "17:30": "17:30",
  },
  {
    "9:00": "-",
    "9:30": "9:30",
    "10:00": "-",
    "10:30": "10:30",
    "11:00": "-",
    "11:30": "11:30",
    "15:00": "-",
    "15:30": "15:30",
    "16:00": "-",
    "16:30": "16:30",
    "17:00": "-",
    "17:30": "17:30",
  },
];

// document.addEventListener("scroll", function () {
//   var secondToolbar = document.querySelector(".slotsec");
//   var map = document.querySelector(".doclist");

//   if (window.pageYOffset + 50 > map.clientHeight)
//     secondToolbar.classList.add("fixed");
//   else secondToolbar.classList.remove("fixed");
// });
