import { FilterPipe } from "./../filter.pipe";
import { LoginService } from "./../login/login.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { DoctorListingService } from "./doctor-listing.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { PageEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { TimeFormat } from "../filter.pipe";
//get data from local config file
import {
  consultationFilter,
  genderFilter,
  availabilityFilter,
  sortbyFilter,
  time_availability,
  consultationOffer,
} from "./doctor-listing.config";
import * as _moment from "moment";
import { Moment } from "moment";
import { element } from "protractor";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import * as publicIp from 'public-ip';

const moment = _moment;
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentdate = new Date().getDate();
const Ip = publicIp;
@Component({
  selector: "app-doctor-listing",
  templateUrl: "./doctor-listing.component.html",
  styleUrls: ["./doctor-listing.component.scss"],
  providers: [DatePipe, CookieService],
})
export class DoctorListingComponent implements OnInit, AfterViewInit {
  // IMGURL = environment.imgUrl;
  p: number = 0;
  @Input() limit: number = 170; //show doc_info till 170 charcters
  truncating: any = []; //showMore all doc_info t
  popuptruncating = true;
  startIndex = 0; //pagination start index
  endIndex = 20; //pagination end index
  minDate: Moment;
  maxDate: any;
  myControl = new FormControl();
  doctorDetails: any = [];
  consultation = consultationFilter;
  gender = genderFilter;
  availability = availabilityFilter;
  consultationOffer = consultationOffer;
  sortby = sortbyFilter;
  selected_consultation: any = [];
  selected_gender: any = [];
  selected_avail: any = [];
  selected_org: any = [];
  organizations: any;
  procedure: any;
  time_availability: any = [];
  conditions: any;
  selected_conditions: any = [];
  selected_sort: any = [];

  selected_city: any = [];
  selected_procedure: any = [];
  showSPL;
  showSymt;
  showSpecialist;
  showConsultType = false;
  events: Event[] = [];
  showGender = false;
  showAvailability = false;
  showSort = false;
  showLang;
  modalRef: any;
  reqSub: any;
  @ViewChild("content", { static: true }) content;
  @ViewChild("confirmTimezone", { static: true }) confirmTimezone;
  listData: any;
  symptoms: any;
  specialist: any;
  allLang: any;
  langId: any = [];
  symtId: any = [];
  specialistId: any = [];
  conditionId: any = [];
  procedureId: any = [];
  show_symtm: any = "Symptoms";
  show_specialist: any = "Specialities";
  show_condition: any = "Conditions";
  show_lang: any = "Languages";
  show_procedure: any = "Procedure";
  filterdate: any;
  isMobileView = false;
  cityAll: any;
  cities: any;
  countries: any;
  showCondition;
  showProcedure;
  options: any = [];
  userData: any = [];
  maindropdown: any = [];
  //for pagination config options
  lessonsCount: any;
  pageSize = 10;
  // pageSizeOptions = [20, 50, 100];
  PageEvent: PageEvent;
  maxlen = 5; //count for date for desktop
  minlen = 1; //min count for date for responsive
  arr_len: any = this.maxlen;
  date_increment: any = this.maxlen; // for next date curosal
  isDisplayView: boolean = true; //for showing desktop view of schedule
  isPrevious = false; //disable previous button for data
  //for dateTimeSlot and calender
  weekDays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  formatedDate: any;
  selected_main: string;
  days: any = [];
  currDate: any;
  dynamicdate: any;
  public innerWidth: any;
  //timeslots fields for popup
  popup_days: any = [];
  popup_currDate: any;
  popup_dynamic_date: any;
  slotseleted: any;
  loading = true;
  secondOpinionLoading = false;
  popupLoad = false;
  slotLoad = false;
  assetUrl = environment.assetUrl;
  isFreeConsultation;
  isFreeText: any = "Free Covid19";
  patient_timezone: any = "IST";
  // filterData: any = [];
  showToggle:boolean = false;
  serachBoxValue: any;
  ip_address: any;
  old_ip_address: any;
  browser_timezone: any;
  old_browser_timezone: any;
  // response: any;
  browser_timezone_selected: boolean = true;
  storage_timezone: any;
  patient_id: any;
  reload: boolean = false;
  loadingLang = false;
  loadingLoc = false;
  loadingSpec = false;
  isClicked: boolean = false;
  isCovidClicked: boolean = false;

  @HostListener("window:resize", ["$event"]) onResize(event) {
    this.innerWidth = event.target.innerWidth;
  }
  getAllLanguages(){
    this.loadingLang = true;
    if(this.allLang == undefined){
      this.reqSub = this.api.getLanguage().subscribe((jsonData) => {
        this.allLang = jsonData.data; //get all Languages  
        this.loadingLang = false;   
      });
    }else{
      this.loadingLang = false;
    }
  }
  getAllLocation(){
     this.loadingLoc = true;
    if(this.countries == undefined){
        this.reqSub = this.api.getAllCity().subscribe((jsonData) => {
          this.countries = jsonData.data;
          let addAll = {
            id_city: "",
            city_name: "All Locations",
            country_id_country: "0",
            country_name: "Locations",
          };
          this.countries.unshift(addAll);
          this.loadingLoc = false;
        });
    }else{
      this.loadingLoc = false;
    }
  }
  getAllSpecialists(){
    this.loadingSpec = true;
    if(this.specialist == undefined){
      this.reqSub = this.api.getDoctorCategory().subscribe((jsonData) => {
        this.specialist = jsonData.data; //get all specialist
        this.loadingSpec = false;
      });
    }else{
      this.loadingSpec = false;
    }
  }
 ngAfterViewInit() {
    //get list of languages from filter
    // this.reqSub = this.api.getLanguage().subscribe((jsonData) => {
    //   this.allLang = jsonData.data; //get all Languages
     
    // });
    if(this.allLang != undefined){
      console.log(this.allLang);
       this.allLang.filter((element) => {
        this.langId.forEach((data) => {
          if (Number(data) === Number(element.id_language)) {
            if (this.langId.length == 1) {
              this.show_lang = element.language_name;
            } else {
              this.show_lang = this.langId.length + " Languages";
            }
          }
        });
      });
    }
    // if (params["specialist"]) {
    //   speciality = params["specialist"];
    // this.specialistId = speciality.split(",");
    // this.reqSub = this.api.getDoctorCategory().subscribe((jsonData) => {
    //   this.specialist = jsonData.data; //get all specialist
    if(this.specialist != undefined){
      this.specialist.filter((element) => {
        this.specialistId.forEach((data) => {
          if (Number(data) === Number(element.id_speciality)) {
            if (this.specialistId.length == 1) {
              this.show_specialist = element.category;
            } else {
              this.show_specialist = this.specialistId.length + " Specialities";
            }
          }
        });
      });
    }
    // });
    this.toggleSpecialistCheck(this.specialistId.length);

    // }
    //get list of conditions from filter
    // this.reqSub = this.api.getMedicalCondition().subscribe((jsonData) => {
    //   this.conditions = jsonData.data;
    //   this.conditions.filter((element) => {
    //     this.conditionId.forEach((data) => {
    //       if (Number(data) === Number(element.id)) {
    //         if (this.conditionId.length == 1) {
    //           this.show_condition = element.name;
    //         } else {
    //           this.show_condition = this.conditionId.length + " Conditions";
    //         }
    //       }
    //     });
    //   });
    // });
    //show list of procedure after filter
    // this.reqSub = this.api.getProcedure().subscribe((jsonData) => {
    //   this.procedure = jsonData.data;
    //   this.procedure.filter((element) => {
    //     this.procedureId.forEach((data) => {
    //       if (Number(data) === Number(element.id)) {
    //         if (this.procedureId.length == 1) {
    //           this.show_procedure = element.name;
    //         } else {
    //           this.show_procedure = this.procedureId.length + " Procedure";
    //         }
    //       }
    //     });
    //   });
    // });
    //get list of symptoms
    // this.reqSub = this.api.getAllsymptom().subscribe((jsonData) => {
    //   this.symptoms = jsonData.data; //get all symptoms
    //   this.symptoms.filter((element) => {
    //     this.symtId.forEach((data) => {
    //       if (Number(data) === Number(element.id_symptom)) {
    //         if (this.symtId.length == 1) {
    //           this.show_symtm = element.symptom_name;
    //         } else {
    //           this.show_symtm = this.symtId.length + " Symptoms";
    //         }
    //       }
    //     });
    //   });
    // });
  }

  toggleShow(){
    this.showToggle = ! this.showToggle;
  }
  collection = [];
  ngOnInit() {
    // this.browser_timezone_selected = JSON.parse(localStorage.getItem('browser_timezone_selected'))?true:false;
    this.storage_timezone = localStorage.getItem('timezone')?localStorage.getItem('timezone'):"Asia/Kolkata";
    window.onresize = () => (this.isMobileView = window.innerWidth <= 767);
    this.isPrevious = false;
    for (let i = 1; i <= 100; i++) {
      this.collection.push(`item ${i}`);
    }
    //calender starts from current-date
    this.minDate = moment(Date.now());
    //on value change for autocomplete dropdown
    this.myControl.valueChanges.subscribe((data) => {
      if (data) {
        if (data.length > 2) {
          this.api.getSpecialistbySearch(data).subscribe((response) => {
            let searchResult = response.data;
            this.options = searchResult;
          });
        } else {
          this.selected_procedure = [];
          this.options = this.maindropdown;
        }
      } else {
        this.options = this.maindropdown;
        this.selected_procedure = [];
        this.filterSubmit(this.startIndex, this.endIndex);
      }
    });
    localStorage.removeItem("doctor_detils");
    localStorage.removeItem("appointment_data");

    if (this.loginService.isLoggedIn()) {
      this.api.getPatientData().subscribe((response: any) => {
        let patientInfo = response.data;
        this.patient_id = patientInfo.id;
        this.patient_timezone = patientInfo.timezone;
        if (this.patient_timezone != this.storage_timezone) {
          this.browser_timezone = localStorage.getItem('timezone');
          this.reload = true;
          this.updatePatientTimezone();
        }
        if(patientInfo.timezone === 'Asia/Kolkata'){
          this.patient_timezone = 'IST'
        }
      });
    }
    this.setIpAddressAndTimezone();
  }

  //for searchFilter of medical conditions
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selected_procedure = event.option.value;
    this.serachBoxValue = this.selected_procedure.type + "_" + this.selected_procedure.id;
    this.getOptionText(this.selected_procedure);
    this.p = 1; // page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  // datafilter=[];
  constructor(
    private modalService: NgbModal,
    private api: DoctorListingService,
    private datePipe: DatePipe,
    public datepipe: DatePipe,
    private route: Router,
    private loginService: LoginService,
    private timeFormat: TimeFormat,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    this.langId = [];
    this.conditionId = [];
    this.procedureId = [];
    this.symtId = [];
    // this.specialistId = [];
    const url = environment.mainUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("flag", "/", "." + url);
    this.filterdate = new Date();
    this.selected_consultation = this.consultation[0].id; //byDefault video Consult selected
    // this.isFreeConsultation = this.consultationOffer[0].id; //byDefault Free Consult selected
    this.activatedRoute.queryParams.forEach((params: Params) => {
      this.selected_city = params["city"];
      this.filterdate =
        params["date"] && params["date"] >= new Date()
          ? params["date"]
          : new Date();
      this.selected_consultation = params["consult"]
        ? params["consult"]
        : this.selected_consultation;
      this.selected_gender = params["gender"];
      this.selected_avail = params["availability"];
      this.selected_sort = params["sort"] ? params["sort"] : "default";
      this.selected_org = params["organization"];
      this.serachBoxValue = params["mainproce"] ? params["mainproce"] : '';
       
       
      // this.isFreeConsultation = params["is_free"]
      //   ? params["is_free"]
      //   : this.consultationOffer[0].id;
      
      // this.isFreeConsultation = params["is_free"];

      // if (
      //   params["mainproce"] != undefined &&
      //   params["maindropdown"] != undefined
      // ) {
      //   this.selected_procedure =
      //     this.selected_procedure != undefined
      //       ? JSON.parse(this.selected_procedure)
      //       : [];
      //   this.getOptionText(this.selected_procedure);
      // } else {
      //   this.selected_procedure = [];
      // }
      this.selected_avail = params["availability"];
      // ? params["availability"]
      // : this.availability[0].id;

      this.langId = [];
      this.symtId = [];
      this.specialistId = [];
      this.procedureId = [];
      this.conditionId = [];
      let language, procedure, condition, speciality, symptos;
      if (params["language"]) {
        language = params["language"];
        this.langId = language.split(",");
      }
      if (params["symptoms"]) {
        symptos = params["symptoms"];
        this.symtId = symptos.split(",");
      }
      if (params["specialist"]) {
        speciality = params["specialist"];
        this.specialistId = speciality.split(",");
      }
      // if (params["specialist"]) {
      //   speciality = params["specialist"];
      //   this.specialistId = speciality.split(",");
      //   this.reqSub = this.api.getDoctorCategory().subscribe((jsonData) => {
      //     this.specialist = jsonData.data; //get all specialist
      //     this.specialist.filter((element) => {
      //       this.specialistId.forEach((data) => {
      //         if (Number(data) === Number(element.id_speciality)) {
      //           if (this.specialistId.length == 1) {
      //             this.show_specialist = element.category;
      //           } else {
      //             this.show_specialist =
      //               this.specialistId.length + " Specialities";
      //           }
      //         }
      //       });
      //     });
      //   });
      // }
      if (params["procedure"]) {
        procedure = params["procedure"];
        this.procedureId = procedure.split(",");
      }
      if (params["condition"]) {
        condition = params["condition"];
        this.conditionId = condition.split(",");
      }
    });
    //get list of languages
    // this.reqSub = this.api.getLanguage().subscribe((jsonData) => {
    //   this.allLang = jsonData.data; //get all Languages
    // });
    //get list of condition
    // this.reqSub = this.api.getMedicalCondition().subscribe((jsonData) => {
    //   this.conditions = jsonData.data;
    // });
    //set max len for timeslots
    if (this.innerWidth > 991) {
      this.arr_len = this.maxlen;
      this.changenextDate();
    } else if (this.innerWidth < 991) {
      this.arr_len = this.minlen;
      this.changenextDate();
    }

    this.time_availability = time_availability;
    //show calender for next 30 days
    this.maxDate = new Date(currentYear, currentMonth, currentdate + 30);
    this.set_date(this.filterdate);

    this.changenextDate();
    //get list of condition,procedure,symptoms,speciality
    this.reqSub = this.api.getCompleteList().subscribe((jsonData) => {
      this.maindropdown = jsonData.data;
      this.options = this.maindropdown;
    });
    //get list of symptoms
    // this.reqSub = this.api.getAllsymptom().subscribe((jsonData) => {
    //   this.symptoms = jsonData.data; //get all symptoms
    // });
    //get list of specialist
    // this.reqSub = this.api.getDoctorCategory().subscribe((jsonData) => {
    //   this.specialist = jsonData.data; //get all specialist
    // });
    //get list of organizations
    // this.reqSub = this.api.getOrganization().subscribe((jsonData) => {
    //   this.organizations = jsonData.data;
    // });
    //get list of procedure
    // this.reqSub = this.api.getProcedure().subscribe((jsonData) => {
    //   this.procedure = jsonData.data;
    // });
    //get list of cities with countries
    // this.reqSub = this.api.getAllCity().subscribe((jsonData) => {
    //   this.countries = jsonData.data;
    //   let addAll = {
    //     id_city: "",
    //     city_name: "All Locations",
    //     country_id_country: "0",
    //     country_name: "Locations",
    //   };
    //   this.countries.unshift(addAll);
    // });
    //get list of condition
    // this.reqSub = this.api.getMedicalCondition().subscribe((jsonData) => {
    //   this.conditions = jsonData.data;
    // });
  }
  resetFilter() {
    this.selected_city = [];
    this.filterdate = new Date();
    this.selected_consultation = this.consultation[0].id; //byDefault video Consult selected
    this.isFreeConsultation = this.consultationOffer[0].id; //byDefault Free Consult selected
    this.selected_gender = undefined;
    // this.selected_avail = this.availability[0].id; // bydefault select today filter
    this.selected_avail = [];
    this.selected_sort = "default";
    this.selected_org = [];
    this.serachBoxValue = [];
    this.langId = [];
    this.symtId = [];
    this.specialistId = [];
    this.procedureId = [];
    this.conditionId = [];
    this.show_lang = "Languages";
    this.show_specialist = " Specialities";
    this.showToggle = false;
    this.isCovidClicked = false;
    this.isClicked = false;
    this.myControl.reset();
    this.p = 1; //page is reset to 1   
    this.filterSubmit(this.startIndex, this.endIndex);
  }

   onCovidClick(){
    this.isClicked = false;
    this.isCovidClicked = !this.isCovidClicked;     
  }
  onAllClick(){
    this.isCovidClicked = false;
    this.isClicked = !this.isClicked;     
  }

  //open pop-up for doctor-details
  openVerticallyCentered(content, docid) {
    this.popupLoad = true;
    this.popuptruncating = true;
    this.modalRef = this.modalService.open(content, { centered: false });
    let date = new Date(this.filterdate); //new Date();
    this.popup_dynamic_date = date;
    // this.getDoctorDetails(docid);
    this.popup_changenextDate(docid);
  }

  toggleLangCheck(elementId) {
    return this.langId.indexOf(elementId) != -1 ? true : false;
  }
  toggleConditionCheck(elementId) {
    return this.conditionId.indexOf(elementId) != -1 ? true : false;
  }
  toggleProcedureCheck(elementId) {
    return this.procedureId.indexOf(elementId) != -1 ? true : false;
  }
  toggleSymptsCheck(elementId) {
    return this.symtId.indexOf(elementId) != -1 ? true : false;
  }
  toggleSpecialistCheck(elementId) {
    return this.specialistId.indexOf(elementId) != -1 ? true : false;
  }

  /*for calender-date picker(time-availability) start*/
  ondateChange(event) {
    this.isPrevious = false;
    this.loading = true;
    let date = this.filterdate;
    this.dynamicdate = new Date(date);
    this.popup_currDate = new Date(date);
    this.changenextDate();
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  changenextDate() {
    // if (this.dynamicdate >= this.endDate) {
    //   this.currDate = this.dynamicdate = new Date();
    // }
    this.slotLoad = true;
    this.days = [];

    if (this.dynamicdate >= this.maxDate) {
      this.currDate = this.dynamicdate = new Date();
      this.isPrevious = false;
    }
    this.currDate < this.dynamicdate
      ? (this.isPrevious = true)
      : this.isPrevious;
    new Date() < this.dynamicdate ? (this.isPrevious = true) : this.isPrevious;
    this.innerWidth = window.innerWidth;
    this.innerWidth > 991
      ? ((this.date_increment = this.maxlen), (this.isDisplayView = true))
      : ((this.date_increment = this.minlen), (this.isDisplayView = false));
    for (let i = 0; i < this.date_increment; i++) {
      this.days.push(new Date(this.dynamicdate));
      this.dynamicdate.setDate(this.dynamicdate.getDate() + this.minlen);
    }
    this.popup_currDate = new Date(this.days[0]);
    this.popup_dynamic_date = new Date(this.days[4]);
    if (this.dynamicdate < new Date(this.days[4])) {
      this.currDate = this.dynamicdate = new Date();
    }

    this.filterSubmit(this.startIndex, this.endIndex);
  }
  changepreviousDate(sdate, edate) {
    this.slotLoad = true;
    this.days = [];
    this.innerWidth = window.innerWidth;
    this.innerWidth > 991
      ? ((this.date_increment = this.maxlen), (this.isDisplayView = true))
      : ((this.date_increment = this.minlen), (this.isDisplayView = false));
    for (let i = this.date_increment; i > 0; i--) {
      this.dynamicdate.setDate(this.dynamicdate.getDate() - 1);
      this.days.push(new Date(this.dynamicdate));
    }
    this.days.reverse();
    // this.currDate >= this.dynamicdate
    //   ? (this.isPrevious = !this.isPrevious)
    //   : this.isPrevious;
    this.isPrevious =
      new Date() < this.dynamicdate ? this.isPrevious : !this.isPrevious;

    this.filterSubmit(this.startIndex, this.endIndex);
  }

  /*for calender-date picker(time-availability) end*/
  filteroptions: any = [];
  //call filter API
  filterSubmit(start, page_limit) {
    if (this.selected_consultation == "second_opinion") {
      this.secondOpinionLoading = true;
      // this.selected_city = [];
    }
    if (!this.slotLoad) this.loading = true;
    // if (this.selected_procedure.name < 3) {
    if (this.selected_procedure.length <= 0) {
      this.selected_procedure = [];
    } else {
      this.selected_procedure = this.selected_procedure;
    }
    let sdate =
      this.days[0] <= Date.now()
        ? this.datePipe.transform(moment(Date.now()), "yyyy-MM-dd")
        : this.datePipe.transform(this.days[0], "yyyy-MM-dd");
    let fdate =
      this.selected_consultation == "second_opinion"
        ? this.datePipe.transform(moment(Date.now()), "yyyy-MM-dd")
        : this.filterdate <= Date.now()
        ? this.datePipe.transform(moment(Date.now()), "yyyy-MM-dd")
        : this.datePipe.transform(this.filterdate, "yyyy-MM-dd");
    this.slotseleted = -1;
    this.show_allTimeslots(this.slotseleted);
    let spcl_id = this.specialistId.join();
    let lang = this.langId.join();
    let conditn = this.conditionId.join();
    let sympts = this.symtId.join();
    let proc = this.procedureId.join();
    this.filteroptions = {
      page: this.p,
      city: this.selected_city,
      date: fdate,
      startdate: sdate,
      consult: this.selected_consultation,
      specialist: spcl_id,
      language: lang,
      symptoms: sympts,
      gender: this.selected_gender,
      availability: this.selected_avail,
      sort: this.selected_sort,
      mainproce: this.serachBoxValue,
      organization: this.selected_org,
      procedure: proc,
      // speciality_id: this.speciality_id,
      condition: conditn,
      start: start,
      limit: page_limit,
      maxlen: this.arr_len,
      is_free: this.isFreeConsultation,
      // timezone: JSON.parse(localStorage.getItem('browser_timezone_selected')) ?
      //                        localStorage.getItem("timezone"):'Asia/Kolkata',
      timezone: localStorage.getItem('timezone')
    };
    this.reqSub = this.api
      .listFilterNew(this.filteroptions)
      .subscribe((jsonData) => {
        this.listData = jsonData.data;
        this.lessonsCount = jsonData.total;
        this.loading = false;
        this.secondOpinionLoading = false;
        this.slotLoad = false;
        let size = this.lessonsCount / 10;
        size =
          size > Math.trunc(size) ? Math.trunc(size) + 1 : Math.trunc(size);
        if (size < this.p) {
          this.p = 0;
        }
        // this.getTimings(jsonData.data.time_availability);
      });

    // this.p = 0;
    // localStorage.setItem("filterdata", JSON.stringify(this.filteroptions));
  }

  //add-remove languages
  getSelectedValue(value: String, lang: string) {
    if (this.langId.includes(value)) {
      var index = this.langId.indexOf(value);
      this.langId.splice(index, 1);
    } else {
      this.langId.push(value);
    }
    //show selected language name/count
    if (this.langId.length > 0) {
      if (this.langId.length == 1) {
        for (let lang of this.allLang) {
          if (this.langId.includes(lang.id_language))
            this.show_lang = lang.language_name;
        }
      } else {
        this.show_lang = this.langId.length + " Languages";
      }
    } else {
      this.show_lang = "Languages";
    }
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  available_slots = false;
  getTimings(time_availability) {
    let i = 0;
    let is_available = "yes";
    time_availability.forEach((element, index) => {
      if (element.avail_day == "no") {
        i++;
      }
    });

    if (i == 5) is_available = "no";

    switch (is_available) {
      case "yes":
        return "yes";
      case "no":
        return "no";
    }
  }

  //add-remove symtoms start
  getSelectedSymtms(value: String, symtm: string) {
    if (this.symtId.includes(value)) {
      var index = this.symtId.indexOf(value);
      this.symtId.splice(index, 1);
    } else {
      this.symtId.push(value);
    }
    //show selected symptoms name/count
    if (this.symtId.length > 0) {
      if (this.symtId.length == 1) {
        for (let symt of this.symptoms) {
          if (this.symtId.includes(symt.id_symptom))
            this.show_symtm = symt.symptom_name;
        }
      } else {
        this.show_symtm = this.symtId.length + " Symptoms";
      }
    } else {
      this.show_symtm = "Symptoms";
    }
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  //add-remove symtoms end
  //add-remove specialist start
  getSelectedSpecialist(value: String, symtm: string) {
    if (this.specialistId.includes(value)) {
      var index = this.specialistId.indexOf(value);
      this.specialistId.splice(index, 1);
    } else {
      this.specialistId.push(value);
    }
    let count = 0;
    //show selected symptoms name/count
    if (this.specialistId.length > 0) {
      for (let special of this.specialist) {
        if (this.specialistId.includes(special.id_speciality)) {
          count++;
          this.show_specialist = special.category;
        }
      }
      if (count == 1) {
      } else {
        this.show_specialist = this.specialistId.length + " Specialities";
      }
    } else {
      this.show_specialist = "Specialities";
    }
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  //add-remove symtoms end
  // getSelectedConditions(value: String, condition_nm: string) {
  //   if (this.conditionId.includes(value)) {
  //     var index = this.conditionId.indexOf(value);
  //     this.conditionId.splice(index, 1);
  //   } else {
  //     this.conditionId.push(value);
  //   }
  //   //show selected Symtom name/count
  //   if (this.conditionId.length > 0) {
  //     if (this.conditionId.length == 1) {
  //       for (let con of this.conditions) {
  //         if (this.conditionId.includes(con.id)) this.show_condition = con.name;
  //       }
  //     } else {
  //       this.show_condition = this.conditionId.length + " Conditions";
  //     }
  //   } else {
  //     this.show_condition = "Conditions";
  //   }
  //   this.filterSubmit(this.startIndex, this.endIndex);
  // }

  getSelectedProcedure(value: String, proc_name: string) {
    if (this.procedureId.includes(value)) {
      var index = this.procedureId.indexOf(value);
      this.procedureId.splice(index, 1);
    } else {
      this.procedureId.push(value);
    }
    //show selected procedure name/count
    if (this.procedureId.length > 0) {
      if (this.procedureId.length == 1) {
        for (let proc of this.procedure) {
          if (this.procedureId.includes(proc.id))
            this.show_procedure = proc.name;
        }
      } else {
        this.show_procedure = this.procedureId.length + " Procedure";
      }
    } else {
      this.show_procedure = "Procedure";
    }
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  getConsultationType(consult_type) {
    this.isFreeConsultation = (consult_type === true) ? this.consultationOffer[0].id
                              : this.consultationOffer[1].id ;   
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  getAvailabilityFilter() {
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  getSelectedGender() {
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  getSelectedLocation() {
    this.p = 1; //page is reset to 1
    this.filterSubmit(this.startIndex, this.endIndex);
  }
  //add-remove procedure end

  //get doctor details
  getDoctorDetails(doc_id) {
    let selecteddate = this.datePipe.transform(
      this.popup_days[0],
      "yyyy-MM-dd"
    );
    this.reqSub = this.api
      .doctorDetails(doc_id, selecteddate, this.selected_consultation, this.filteroptions)
      .subscribe(
        (jsonData) => {
          this.doctorDetails = jsonData.data;
          this.doctorDetails.price_info = {
            price: this.doctorDetails.price,
            original_price: this.doctorDetails.original_price,
            total: this.doctorDetails.total,
            gst_amount: this.doctorDetails.gst_amount,
            gst_percentage: this.doctorDetails.gst_percentage,
            discount_amount: this.doctorDetails.discount_amount,
            discount_percentage: this.doctorDetails.discount_percentage,
          };
          this.popupLoad = false;
        },
        (err) => {}
      );
  }
  maxSize;
  @Output() pageChange: EventEmitter<number>;
  @Output() pageBoundsCorrection: EventEmitter<number>;
  //added for pagination
  pagination_control(event: any) {
    // let offset = e.pageIndex;
    // this.pageSize = e.pageSize;
    this.p = event;
    this.maxSize = this.lessonsCount;
    this.maxSize =
      this.maxSize > Math.trunc(this.maxSize)
        ? Math.trunc(this.maxSize) + 1
        : Math.trunc(this.maxSize);
    this.filterSubmit(event, this.pageSize);
  }

  //assign value to localstorage and
  //call route by user by checking islogged-in
  book_doctor(
    fname,
    lname,
    category,
    time,
    id,
    price,
    tax_amount,
    total,
    index,
    date_frompopup,
    profile_pic,
    booking_mode_index,
    education,
    rating_average,
    rating_total,
    original_price,
    discount_amount,
    discount_percentage,
    tax_percentage
  ) {
    var booking_Date = this.popup_days[index];
    // booking_Date = this.innerWidth < 991 ? this.days[index] : ;
    booking_Date =
      date_frompopup == 1 ? this.popup_days[index] : this.days[index];
    // booking_Date.setDate(booking_Date.getDate() + index);
    let pic = profile_pic ? profile_pic : "";
    let sdate =
      this.days[0] <= Date.now()
        ? this.datePipe.transform(moment(Date.now()), "yyyy-MM-dd")
        : this.datePipe.transform(this.days[0], "yyyy-MM-dd");
    booking_Date = booking_Date ? booking_Date : sdate;
    let doc_details = {
      fname: fname,
      lname: lname,
      category: category,
      consultation: this.listData[booking_mode_index].booking_mode
        ? this.listData[booking_mode_index].booking_mode
        : this.selected_consultation,
      bookdate: this.datepipe.transform(booking_Date, "yyyy-MM-dd"),
      booktime: time.value,
      iddoc: id,
      total: total,
      profile_pic: pic,
      education: education,
      rating_average: rating_average,
      rating_total: rating_total,
      price_info: {
        price: price,
        original_price: original_price,
        total: total,
        tax_amount: tax_amount,
        tax_percentage: tax_percentage,
        discount_amount: discount_amount,
        discount_percentage: discount_percentage,
      },
    };

    let appointment_data = {
      doctor_id: id,
      consultation_mode: this.listData[booking_mode_index].booking_mode
        ? this.listData[booking_mode_index].booking_mode
        : this.selected_consultation,
      appointment_date: this.datepipe.transform(booking_Date, "yyyy-MM-dd"),
      appointment_time: time.value,
    };

    // localStorage.setItem("doctor_detils", JSON.stringify(doc_details));
    localStorage.setItem("appointment_data", JSON.stringify(appointment_data));
    this.modalService.dismissAll();
    if (this.selected_consultation != "second_opinion") {
      if (this.loginService.isLoggedIn()) {
        this.api.getPatientData().subscribe(
          (response: any) => {
            let patientInfo = response.data;
            // let appointment_data = {
            //   patient_id: patientInfo.id,
            //   doctor_id: id,
            //   consultation_mode: this.listData[booking_mode_index].booking_mode
            //   ? this.listData[booking_mode_index].booking_mode
            //   : this.selected_consultation,
            //   appointment_date: this.datepipe.transform(booking_Date, "yyyy-MM-dd"),
            //   appointment_time: time.value
            // };

            appointment_data["patient_id"] = patientInfo.id;

            this.api.addToAppointmentCart(appointment_data).subscribe(
              (response: any) => {
                if (patientInfo.is_profile_completed == 1) {
                  this.route.navigateByUrl("/review-book");
                } else {
                  this.loginService.navigatePatient();
                }
              },
              (errors: any) => {}
            );
          },
          (err) => {}
        );
      } else {
        this.route.navigateByUrl("/login");
      }
    }
  }

  getModeValue(value, index) {
    this.listData[index].booking_mode = value;
  }
  //pass data for second-opinion
  book_SO_doctor(
    fname,
    lname,
    category,
    price,
    gst_amount,
    original_price,
    id,
    profile_pic,
    education,
    rating_average,
    rating_total,
    price_info,
    experience_year,
    experience_month,
    discount_amount,
    discount_percentage,
    gst_percentage,
    total
  ) {
    let date = this.datepipe.transform(Date.now(), "yyyy-MM-dd");
    let pic = profile_pic ? profile_pic : "";
    let doc_details = {
      fname: fname,
      lname: lname,
      bookdate: date,
      category: category,
      consultation: this.selected_consultation,
      iddoc: id,
      price_info: {
        price: price,
        original_price: original_price,
        total: total,
        gst_amount: gst_amount,
        gst_percentage: gst_percentage,
        discount_amount: discount_amount,
        discount_percentage: discount_percentage,
      },
      profile_pic: pic,
      education: education,
      rating_average: rating_average,
      rating_total: rating_total,
      experience_year: experience_year,
      experience_month: experience_month,
    };
    // localStorage.setItem("doctor_detils", JSON.stringify(doc_details));

        let appointment_data = {
      doctor_id: id,
      consultation_mode: this.selected_consultation,
      appointment_date: date,
      appointment_time: this.datepipe.transform(Date.now(), "HH:mm:ss"),
    };

    // localStorage.setItem("doctor_detils", JSON.stringify(doc_details));
    localStorage.setItem("appointment_data", JSON.stringify(appointment_data));
    this.modalService.dismissAll();
    if (this.selected_consultation == "second_opinion") {
      if (this.loginService.isLoggedIn()) {
        this.api.getPatientData().subscribe(
          (response: any) => {
            let patientInfo = response.data;

            appointment_data["patient_id"] = patientInfo.id;

            this.api.addToAppointmentCart(appointment_data).subscribe(
              (response: any) => {
                if (patientInfo.is_profile_completed == 1) {
                  // this.route.navigateByUrl("/review-book");
                } else {
                  this.loginService.navigatePatient();
                }
              },
              (errors: any) => {}
            );
          },
          (err) => {}
        );
      } else {
        this.route.navigateByUrl("/login");
      }
    }
  }

  //set Consult type for booking through doctor-profile
  setConsult() {
    let consultDates = {
      consultation: this.selected_consultation,
      startdate: this.filterdate,
      // enddate: this.endDate,
    };
    localStorage.setItem("consultDates", JSON.stringify(consultDates));
  }

  popup_changenextDate(docid) {
    // if (this.popup_dynamic_date >= this.endDate) {
    //   this.popup_currDate = this.popup_dynamic_date = new Date();
    // }
    this.popup_days = [];
    if (this.popup_dynamic_date >= this.maxDate) {
      this.popup_currDate = this.popup_dynamic_date = new Date();
    }
    this.popup_currDate < this.popup_dynamic_date
      ? (this.isPrevious = true)
      : this.isPrevious;
    new Date() < this.popup_dynamic_date
      ? (this.isPrevious = true)
      : this.isPrevious;

    for (let i = 0; i < this.date_increment; i++) {
      this.popup_days.push(new Date(this.popup_dynamic_date));
      this.popup_dynamic_date.setDate(
        this.popup_dynamic_date.getDate() + this.minlen
      );
    }
    this.getDoctorDetails(docid);
  }
  popup_changepreviousDate(docid) {
    this.popup_days = [];

    for (let i = this.date_increment; i > 0; i--) {
      this.popup_dynamic_date.setDate(this.popup_dynamic_date.getDate() - 1);
      this.popup_days.push(new Date(this.popup_dynamic_date));
    }
    this.popup_days.reverse();
    // this.isPrevious =
    //   new Date() < this.popup_dynamic_date ? this.isPrevious : !this.isPrevious;

    this.isPrevious =
      new Date() > this.popup_dynamic_date ? !this.isPrevious : this.isPrevious;
    this.getDoctorDetails(docid);
  }
  show_allTimeslots(val) {
    this.slotseleted = val;
  }
  //get all lang with filterd data
  getallLangs() {
    //get list of languages
    this.reqSub = this.api.getLanguage().subscribe((jsonData) => {
      this.allLang = jsonData.data; //get all Languages
    });
  }
  endDate;
  set_date(date) {
    // if (date > this.endDate) {
    //   date = new Date();
    // }
    this.currDate = new Date(date);
    this.endDate = this.currDate.setDate(this.currDate.getDate() + this.maxlen);
    this.dynamicdate = new Date(date);
    this.popup_currDate = new Date(date);
    this.popup_dynamic_date = new Date(date);
    this.popup_dynamic_date.setDate(new Date(date).getDate() + this.maxlen);
  }
  showLess() {
    this.show_allTimeslots(-1);
    window.scroll(0, 0);
  }
  toggletruncating(val, data) {
    switch (data) {
      case "more":
        for (let i = 0; i < this.lessonsCount; i++) {
          this.truncating[i] = false;
          if (i == val) {
            this.truncating[val] = !this.truncating[val];
          }
        }
        break;
      case "less":
        this.truncating[val] = !this.truncating[val];
        break;
    }
  }
  getOptionText(option) {
    if (option == null && this.selected_procedure) {
      return this.selected_procedure ? this.selected_procedure.name : "";
    }
    return option ? option.name : "";
  }
  update_consultation() {
    this.selected_city = [];
    this.filterSubmit(this.startIndex, this.endIndex);
  }

  async setIpAddressAndTimezone() {
      this.loading = true;
      this.old_ip_address = localStorage.getItem("ip_adress");
      this.old_browser_timezone = localStorage.getItem("timezone");

      if (this.old_ip_address && this.old_browser_timezone) {
          await this.getIPAddress();
          if (this.ip_address != this.old_ip_address && this.ip_address) {
              await this.getBrowserTimezone(this.ip_address);
              if (this.browser_timezone != 'undefined' && !!this.browser_timezone) {
                this.updatePatientTimezone();
                localStorage.removeItem('ip_adress');
                localStorage.removeItem('timezone');
                localStorage.setItem("ip_adress", this.ip_address);
                localStorage.setItem("timezone", this.browser_timezone);

                if (this.old_browser_timezone != this.browser_timezone) {
                    window.location.reload();
                } else {
                    this.loading = false;
                  // this.modalRef = this.modalService.open(this.confirmTimezone, { centered: false });
                }
              } else {
                this.loading = false;
              }
          }
      } else {
          await this.getIPAddress();
          await this.getBrowserTimezone(this.ip_address);
        if (this.ip_address && this.browser_timezone) {
          // if ("Asia/Kolkata" == this.browser_timezone) {
            localStorage.setItem("ip_adress", this.ip_address);
            localStorage.setItem("timezone", this.browser_timezone);
            window.location.reload();
          // } else {
          //     this.modalRef = this.modalService.open(this.confirmTimezone, { centered: false });
          // }
        }
    }
  }

  async getIPAddress()
  {
    this.ip_address = await Ip.v4();
    return this.ip_address;
  }

  getBrowserTimezone(ip_address)
  {
    this.loading = true;
    return new Promise(resolve => {
      this.api.getBrowserTimezoneApi(ip_address).subscribe(
        (response: any) => {
          if (response.timezone != 'undefined') {
            this.browser_timezone = response.timezone;
          }
        },
        (err) => {}
        );
        setTimeout(() => resolve(`Promise Resolved:`), 5000);
      });
  }

  timezoneConfirmation(status)
  {
    if(status) {
      //remove old ip and timezone.
      localStorage.removeItem('ip_adress');
      localStorage.removeItem('timezone');
      //add new ip and timezone.
      localStorage.setItem("ip_adress", this.ip_address);
      localStorage.setItem("timezone", this.browser_timezone);
      localStorage.setItem("browser_timezone_selected", "true");
      this.browser_timezone_selected = true;
      window.location.reload();
    } else {
      localStorage.setItem("ip_adress", this.ip_address);
      localStorage.setItem("timezone", this.browser_timezone);
      localStorage.setItem("browser_timezone_selected", "false");
      this.browser_timezone_selected = false;
      window.location.reload();
    }
  }

  changeTimezone(status) {
    if (status) {
      localStorage.setItem("browser_timezone_selected", "true");
      this.filterSubmit(this.startIndex, this.endIndex);
    } else {
      localStorage.setItem("browser_timezone_selected", "false");
      this.filterSubmit(this.startIndex, this.endIndex);
    }
  }

  updatePatientTimezone() {
    return new Promise(resolve => {
      if (this.loginService.isLoggedIn() && this.browser_timezone != 'undefined') {
            this.api.updateUserTimezoneApi(this.patient_id, this.browser_timezone).subscribe(
            (response: any) => {
              if (this.reload) {
                window.location.reload();
              }
             },
              (err) => {}
            );
            setTimeout(() => resolve(`Promise Resolved:`), 5000)
          }
    });
  }
}
