import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class FooterService {
  constructor(private http: HttpClient) {}

  getSpecialties(): Observable<any> {
    let apiURL = API_URL + "get-doctor-category";
    return this.http.get(apiURL);
  }
}
