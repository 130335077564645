import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  register(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("first_name", user.first_name);
    param = param.append("last_name", user.last_name);
    param = param.append("email", user.email);
    param = param.append("password", user.password);
    param = param.append("confirm_password", user.conf_pass);
    param = param.append("mobile_no", user.mobile);
    param = param.append("country", user.country);
    param = param.append("terms", user.terms);
    param = param.append("country_code", user.country_code);

    return this.http.post(API_URL + "registration", param);
  }

  sentForgetPwdEmail(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("email", user.fgtmail);
    param = param.append("mobile_no", user.fgtMobile);
    return this.http.post(API_URL + "sent-forgetpwd-email", param);
  }

  resetPassSet(user: any, code: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("new_password", user.password);
    param = param.append("conf_password", user.cpassword);
    return this.http.post(API_URL + "reset-password/" + code, param);
  }

  validateResetPass(code: any): Observable<any> {
    return this.http.get(API_URL + "validate-rplink/" + code);
  }

  getCountry(): Observable<any> {
    let apiURL = API_URL + "get-allcountry";
    return this.http.get(apiURL);
  }
  verifyPaymentSignature(order_id, data): Observable<any> {
    return this.http.put(
      API_URL + "bookings/payment/" + order_id + "/verify",
      data
    );
  }

  verifySubscriptionPaymentSignature(subscription_id, data): Observable<any> {
    return this.http.put(
      API_URL + "subscribe-payment/" + subscription_id + "/verify",
      data
    );
  }
  getPatientData() {
    let apiURL = API_URL + "get-user-details";
    return this.http.get(apiURL);
  }
    verifyProductPaymentSignature(data): Observable<any> {
    return this.http.put(
      API_URL + "product/verify-payment",
      data
    );
  }
}
