import { RouterModule, Routes } from "@angular/router";
// layouts
import { ApplayoutComponent } from "./layout/applayout/applayout.component";
import { AuthGuard } from "./_guards/auth.guard";
import { NotFoundComponent } from "./not-found/not-found.component";
import { LogoutComponent } from "./logout/logout.component";
import { environment } from "src/environments/environment";
import { NgModule } from "@angular/core";

export const AppRoutes: Routes = [
  {
    path: "",
    component: ApplayoutComponent,

    // loadChildren: () =>
    //   import("./layout/applayout/applayout.route").then(
    //     (module) => module.ApplayoutModule
    //   ),
    children: [
      // {
      //   path: "",
      //   loadChildren: () =>
      //     import("./doctor-listing/doctor-listing.module").then(
      //       (module) => module.DoctorListingModule
      //     ),
      // },
      {
        path: "",
        loadChildren: () =>
          import("./new-home-page/new-home-page.module").then(
            (module) => module.NewHomePageModule
          ),
      },
      {
        path: "registration/patient-profile",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patient-info/patient-info.module").then(
            (module) => module.PatientInfoModule
          ),
      },
      {
        path: "registration/patient-signup",
        loadChildren: () =>
          import("./patient-registartion/patient-registartion.module").then(
            (module) => module.PatientRegistartionModule
          ),
      },
      {
        path: "registration/otp-verification",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./otp-verification/otp-verification.module").then(
            (module) => module.OtpVerificationModule
          ),
      },
      {
        path: "registration/id-verification",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./id-verification/id-verification.module").then(
            (module) => module.IdVerificationdModule
          ),
      },
      // {
      //   path: "registration/patient-history",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./medical-history/medical-history.module").then(
      //       (module) => module.MedicalHistorydModule
      //     ),
      // },
      {
        path: "review-book",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./review-and-book/review-and-book.module").then(
            (module) => module.ReviewAndBookdModule
          ),
      },
      {
        path: "confirm-pay",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./confirm-and-pay/confirm-and-pay.module").then(
            (module) => module.SoDoctorListingModule
          ),
      },
      {
        path: "so-info",
        loadChildren: () =>
          import("./second-opinion-info/second-opinion-info.module").then(
            (module) => module.SecondOpinionInfoModule
          ),
      },
      {
        path: "booking-status/:value",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./booking-thank-you/booking-thank-you.module").then(
            (module) => module.BookingThankYouModule
          ),
      },
      {
        path: "product",
        loadChildren: () =>
          import("./product/product.module").then(
            (module) => module.ProductModule
          ),
      },
      {
        path: "product-booking-status/:value",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./product-booking-thank-you/booking-thank-you.module").then(
            (module) => module.BookingThankYouModule
          ),
      },
      {
        path: "contact-us",
        loadChildren: () =>
          import("./contact-us/contact-us.module").then(
            (module) => module.ContactUsModule
          ),
      },
      {
        path: "delete-user",
        loadChildren: () =>
        import("./delete/delete.module").then(
          (module) => module.DeleteModule
        ),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./blog/blog.module").then((module) => module.BlogModule),
      },
      {
        path: "about-us",
        loadChildren: () =>
          import("./about-us/about-us.module").then(
            (module) => module.AboutUsModule
          ),
      },
      {
        path: "faq",
        loadChildren: () =>
          import("./faq/faq.module").then((module) => module.FaqModule),
      },
      {
        path: "privacy-policy",
        loadChildren: () =>
          import("./privacy-policy/privacy-policy.module").then(
            (module) => module.PrivacyPolicyModule
          ),
      },
      {
        path: "terms-condition",
        loadChildren: () =>
          import("./terms-condition/terms-condition.module").then(
            (module) => module.TermsConditionModule
          ),
      },
      // {
      //   path: "doctor-listing",
      //   loadChildren: () =>
      //     import("./doctor-listing/doctor-listing.module").then(
      //       (module) => module.DoctorListingModule
      //     ),
      // },
      {
        path: "doctor/:category",
        loadChildren: () =>
          import("./doctor-listing/doctor-listing.module").then(
            (module) => module.DoctorListingModule
          ),
      },
      {
        path: "class/:category",
        loadChildren: () =>
          import("./doctor-listing/doctor-listing.module").then(
            (module) => module.DoctorListingModule
          ),
      },
      {
        path: "gender/:value",
        loadChildren: () =>
          import("./doctor-listing/doctor-listing.module").then(
            (module) => module.DoctorListingModule
          ),
      },
      {
        path: "price/:value",
        loadChildren: () =>
          import("./doctor-listing/doctor-listing.module").then(
            (module) => module.DoctorListingModule
          ),
      },
      {
        path: ":category/:value",
        loadChildren: () =>
          import("./doctor-details/doctor-details.module").then(
            (module) => module.DoctorDetailsModule
          ),
        data: {
          seo: {
            title: "Doctor-details",
            metaTags: [
              {
                name: "description",
                content:
                  "Consult a Doctor Online, via audio/video. Doctors from India and abroad are volunteering &amp; providing free online consultations to COVID affected patients in India.",
              },
              { property: "og:title", content: "GET consultation" },
              {
                proprety: "og:description",
                content:
                  "Consult a Doctor Online, via audio/video. Doctors from India and abroad are volunteering &amp; providing free online consultations to COVID affected patients in India.",
              },
              {
                property: "og:image",
                content: environment.mainUrl + "assets/image/sc_logo1.png",
              },
              {
                property: "og:url",
                content: environment.mainUrl + `{:category/:value}`,
              },
              // { name: "facebook:card", content: "summary_large_image" },
            ],
          },
        },
      },
      {
        path: "doctor-appointment/:value",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctor-appointment/doctor-appointment.module").then(
            (module) => module.DoctorAppointmentModule
          ),
      },
      {
        path: "booking-summary/:value",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./booking-summary/booking-summary.module").then(
            (module) => module.BookingSummaryModule
          ),
      },
      {
        path: "login",
        loadChildren: () =>
          import("./layout/common-login/common-login.module").then(
            (module) => module.CommonLoginModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () =>
          import(
            "./layout/common-registartion/common-registartion.module"
          ).then((module) => module.CommonRegistartionModule),
      },
      {
        path: "patient-login",
        loadChildren: () =>
          import("./login/login.module").then((module) => module.LoginModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("./reset-password/reset-password.module").then(
            (module) => module.ResetPasswordModule
          ),
      },
      {
        path: "membership",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./membership/membership.module").then(
            (module) => module.MembershipModule
          ),
      },
      {
        path: "membership-status",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./membership-thank-you/membership-thank-you.module").then(
            (module) => module.MembershipThankYouModule
          ),
      },
      {
        path: "membership-confirmation",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./membership-confirmation/membership-confirmation.module").then(
            (module) => module.MembershipConfirmationModule
          ),
      },
      {
        path: "app-redirection",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./app-redirection/app-redirection.module").then(
            (module) => module.AppRedirectionModule
          ),
      },
    ],
  },
  // {
  // path: "",
  // component: LoginlayoutComponent,
  // children: [
  //   {
  //     path: "login",
  //     loadChildren: () =>
  //       import("./login/login.module").then((module) => module.LoginModule),
  //   },
  //   {
  //     path: "radiology-register",
  //     loadChildren: () =>
  //       import("./radiology-registration/radiology-registration.module").then(
  //         (module) => module.RadiologyRegistrationModule
  //       ),
  //   },
  // ],
  // },
  {
    path: "patient-info",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./patient-info/patient-info.module").then(
        (module) => module.PatientInfoModule
      ),
  },
  {
    path: "confirmpay/:value",
    loadChildren: () =>
      import("./payment/paymentconfirm/paymentconfirm.module").then(
        (module) => module.PaymentconfirmModule
      ),
  },
  {
    path: "payfail/:value",
    loadChildren: () =>
      import("./payment/paymentfailure/paymentfailure.module").then(
        (module) => module.PaymentfailureModule
      ),
  },
  // {
  //   path: "doctor-signup",
  //   loadChildren: () =>
  //     import("./register-doctor/register-doctor.module").then(
  //       (module) => module.RegisterDoctorModule
  //     ),
  // },
  {
    path: "radiology-center-signup",
    loadChildren: () =>
      import(
        "./radiology-center-registartion/radiology-center-registartion.module"
      ).then((module) => module.RadiologyCenterRegistartionModule),
  },
  {
    path: "payment/:value",
    loadChildren: () =>
      import("./payment/paymentpage/paymentpage.module").then(
        (module) => module.PaymentpageModule
      ),
  },
  {
    path: "not-found",
    component: NotFoundComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
];

// @NgModule({
//   imports: [RouterModule.forRoot(AppRoutes, { initialNavigation: "enabled" })],
//   exports: [RouterModule],
// })
// export class AppRoutingModule {}
